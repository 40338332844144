exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-article-blog-akn-js": () => import("./../../../src/pages/article/blog_akn.js" /* webpackChunkName: "component---src-pages-article-blog-akn-js" */),
  "component---src-pages-article-blog-amd-js": () => import("./../../../src/pages/article/blog_amd.js" /* webpackChunkName: "component---src-pages-article-blog-amd-js" */),
  "component---src-pages-article-blog-dandruff-js": () => import("./../../../src/pages/article/blog_dandruff.js" /* webpackChunkName: "component---src-pages-article-blog-dandruff-js" */),
  "component---src-pages-article-blog-faceacne-js": () => import("./../../../src/pages/article/blog_faceacne.js" /* webpackChunkName: "component---src-pages-article-blog-faceacne-js" */),
  "component---src-pages-article-blog-haircare-js": () => import("./../../../src/pages/article/blog_haircare.js" /* webpackChunkName: "component---src-pages-article-blog-haircare-js" */),
  "component---src-pages-article-blog-hairloss-js": () => import("./../../../src/pages/article/blog_hairloss.js" /* webpackChunkName: "component---src-pages-article-blog-hairloss-js" */),
  "component---src-pages-article-blog-obesity-js": () => import("./../../../src/pages/article/blog_obesity.js" /* webpackChunkName: "component---src-pages-article-blog-obesity-js" */),
  "component---src-pages-article-soln-akn-js": () => import("./../../../src/pages/article/soln_akn.js" /* webpackChunkName: "component---src-pages-article-soln-akn-js" */),
  "component---src-pages-article-soln-amd-js": () => import("./../../../src/pages/article/soln_amd.js" /* webpackChunkName: "component---src-pages-article-soln-amd-js" */),
  "component---src-pages-article-soln-dandruff-js": () => import("./../../../src/pages/article/soln_dandruff.js" /* webpackChunkName: "component---src-pages-article-soln-dandruff-js" */),
  "component---src-pages-article-soln-faceacne-js": () => import("./../../../src/pages/article/soln_faceacne.js" /* webpackChunkName: "component---src-pages-article-soln-faceacne-js" */),
  "component---src-pages-article-soln-haircare-js": () => import("./../../../src/pages/article/soln_haircare.js" /* webpackChunkName: "component---src-pages-article-soln-haircare-js" */),
  "component---src-pages-article-soln-hairloss-js": () => import("./../../../src/pages/article/soln_hairloss.js" /* webpackChunkName: "component---src-pages-article-soln-hairloss-js" */),
  "component---src-pages-article-soln-obesity-js": () => import("./../../../src/pages/article/soln_obesity.js" /* webpackChunkName: "component---src-pages-article-soln-obesity-js" */),
  "component---src-pages-contributors-brijbhushan-js": () => import("./../../../src/pages/contributors/brijbhushan.js" /* webpackChunkName: "component---src-pages-contributors-brijbhushan-js" */),
  "component---src-pages-contributors-index-js": () => import("./../../../src/pages/contributors/index.js" /* webpackChunkName: "component---src-pages-contributors-index-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-research-index-js": () => import("./../../../src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */)
}

